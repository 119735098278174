<script setup>
import Gallery from "@/Components/Gallery.vue";
import {Head} from '@inertiajs/vue3';
import HomePageLayout from "@/Layouts/HomePageLayout.vue";
import Lightbox from "@/Components/Lightbox.vue";</script>

<template>
    <HomePageLayout>
        <Head title="Home">
            <meta content="Klein Vossen Park" name="title">
            <meta
                content="Welkom op onze educatieve (kinder)boerderij en jeugdverblijfcentrum type c in Montenaken die mens en dier in aanraking brengt. "
                name="description">
            <meta content="index, follow" name="robots">
        </Head>

        <div class="mb-8 max-w-6xl mx-auto">
            <h3>Ontdek Klein Vossen Park</h3>
            <Gallery :items="ontdekGalerij"/>
        </div>

        <div class="pt-4 mb-8 max-w-6xl mx-auto">
            <h3>Verken Klein Vossen Park - plattegrond</h3>
            <div class="max-w-xl m-auto">
                <Lightbox image="/public/storage/Afbeeldingen/Home/Plattegrond.png" alt="Plattegrond van Klein Vossen Park" />

<!--                <a href="/public/storage/Documenten/Plannen/Parkplan%20-%20Dieren.jpg" target="_blank" rel="noopener norefferer">-->
<!--                    <img-->
<!--                    src="/public/storage/Afbeeldingen/Home/Plattegrond.png"-->
<!--                    alt="Plattegrond van Klein Vossen Park"-->
<!--                    class="object-cover"-->
<!--                /></a>-->
            </div>
        </div>
    </HomePageLayout>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            // Gewone gallerij
            ontdekGalerij: [
                {
                    imgLink: '/public/storage/Afbeeldingen/Home/Kampen.jpg',
                    routeLink: route("kampen.algemeen"),
                    text: "Kampen",
                    gridArea: '1 / 1 / span 1 / span 1',
                    width: 23,
                    height: 20
                },
                {
                    imgLink: '/public/storage/Afbeeldingen/Home/Verjaardagsfeest.jpg',
                    routeLink: route("activiteiten.verjaardagsfeest"),
                    text: "Verjaardagsfeestjes",
                    gridArea: '1 / 2 / span 1 / span 1',
                    width: 23,
                    height: 20
                },
                {
                    imgLink: '/public/storage/Afbeeldingen/Home/Scholen.jpg',
                    routeLink: route("activiteiten.scholen"),
                    text: "Scholen",
                    gridArea: '1 / 3 / span 1 / span 1',
                    width: 23,
                    height: 20
                },
                {
                    imgLink: '/public/storage/Afbeeldingen/Home/Familiedagen.jpg',
                    routeLink: route("activiteiten.familiedagen"),
                    text: "Familiedagen",
                    gridArea: '2 / 1 / span 1 / span 1',
                    width: 23,
                    height: 20
                },
                {
                    imgLink: '/public/storage/Afbeeldingen/Home/Jeugdverblijfscentrum.jpg',
                    routeLink: route("activiteiten.jeugdverblijfcentrum"),
                    text: "Jeugdverblijfscentrum",
                    gridArea: '2 / 2 / span 1 / span 1',
                    width: 23,
                    height: 20
                },
                {
                    imgLink: '/public/storage/Afbeeldingen/Home/Kidsdays.jpg',
                    routeLink: route("activiteiten.kidsdays"),
                    text: "Kidsdays",
                    gridArea: '2 / 3 / span 1 / span 1',
                    width: 23,
                    height: 20
                }
            ]
        }
    }
}
</script>

<style scoped>

.banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner {
    margin-top: -5em;
    height: 20em;
}


.volleStrook {
    @apply bg-kvpOrange;
    padding: 1em 0 1em 0;
    color: whitesmoke;
}

.volleStrook h3 {
    color: whitesmoke;
}

.galerij img {
    margin: 0;
}

h3 {
    margin: 1rem;
}

</style>
